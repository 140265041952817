html,
body {
  font-size: 16px;
  font-family: "Ubuntu", "Roboto", "Arial", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #edf1ff;
}
::-webkit-scrollbar-thumb {
  background: #4ce5b1;
  border-radius: 10px !important;
}

.container-scroll {
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 20px 25px;
}
